/* 
// variables.scss
*/

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");

// Color variables
$white: #ffffff;
$black: #000000;

$primary: #2558ab;
$secondary: #6c757d;
$success: #15d68c;
$info: #59dbff;
$warning: #254f9b;
$danger: #ee4a4a;
$dark: #343a40;
$muted: #9da9bb;
$light: #f7f9fe;
$body-bg: #ffffff;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
  "body-bg": $body-bg,
  "black": $black,
  "white": $white,
);

// Font
$font-primary: "Rubik", sans-serif;
$font-secondary: "Rubik", sans-serif;
